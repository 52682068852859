import { services_data } from "../../mock-data";
import List from "../../components/list";
import { useTranslation } from 'react-i18next';

/**
 * @page Services
 * Returns Services page
 */
export default function Services() {

    const { t } = useTranslation();

    return (
        <List
            data={services_data}
            title={t("services_title")}
            labels={{
                backbutton: t("services_detail_back_button"),
                structuretitle: t("services_detail_sessions_title")
            }}
        />
    )
}
