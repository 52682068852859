import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./personal-description.css";

/**
 * @component PersonalDescription
 * Contains the person description of eact team element
 */
export default function PersonalDescription({ person }: PersonalProps) {

    const navigate = useNavigate();
    const { t } = useTranslation();
  
    return (
        <div className='personal_description_root'>
            <button
                className="button_text"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('about', { replace: true })}>
                <FaChevronLeft />
                {t("person_detail_back_button")}
            </button>
            <div>
                <div className='personal_description_overview'>
                    <div>
                        <h1>{person?.name}</h1>
                        <h2>{t(person?.job)}</h2>
                    </div>
                    <div className='personal_description_overview_avatar'>
                        <div>
                            <img
                                src={person?.photo}
                                style={{ width: '300px' }} />
                        </div>
                        <div></div>
                    </div>
                    <span>{t(person?.description)} <br/> {t(person?.description2)}</span>
                </div>
                <div className='personal_description_details'>
                    <h3 className="h3">
                        {t("person_detail_experience_title")}
                    </h3>
                    <div className='personal_description_details_person_item'>
                        <div>
                            {person?.experience.map((value, index) => {
                                return (
                                    <div
                                        key={"experiance_" + index}>
                                        <p className='personal_description_details_person_item_date'>{value.range}</p>
                                        <p>{t(value.job)}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='divider_vertical divider' />
                        <div>
                            {
                                person?.experience.map((value, index) => {
                                    return (
                                        <div
                                            key={"experiance_" + index}>
                                            <p>{t(value.description)}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
