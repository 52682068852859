import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "home_title": "Who we are",
            "home_partners_section": "Our Partners",
            "home_contacts_section": "Contact us",
            "home_description_part1": "Birth Tree is made up of four Specialist Nurses in Maternal and Obstetric Health (SNMOH). In the course of their professional career, destiny wanted these nurses to cross paths in the same hospital institution. It was at that meeting that they felt that Obstetrics could go beyond childbirth itself and that childbirth should be a team effort, between the family and health professionals, starting long before that moment.",
            "home_description_part2": "Involved over these years in the preparation, monitoring and empowerment of families for the arrival of a new member, they assumed that they met the conditions to start a project of their own...",
            "home_description_part3": "Thus Birth Tree was born. With the commitment to share, innovate and support women, newborns and families in their environment, in their time and according to their needs and goals, in the transition to positive parenting.",
            "home_testemonials_vanessa": "Excellence professionals! I loved and recommend the preparation session in an aquatic environment and all the postpartum follow-up.",
            "home_testemonials_susana": "(...) I had postpartum follow-up and help with breastfeeding. It was a complicated time, but there was nurse Joana Ventura giving all her support and wisdom so that everything went well. (...) I recommend it, I would go through everything again (...).",
            "home_testemonials_joana": "(...) Thank you Enf. Joana Ventura, who guided me through all the induction hours, with all her patience, understanding, knowledge, respect and affection.(...)",
            "home_testemonials_andreia": "Joana Ventura is just one of the most wonderful people and professionals I've had the pleasure of meeting!",
            "home_testemonials_patricia": "When we start the expulsion, the work of the nurse who accompanies us is fundamental and makes all the difference!! Nurse Joana Ventura was calm, asked me to calm down, to moderate my strength, breathing (...) she always explained everything, encouraged me, praised me: all this made a difference!",
            "home_testemonials_claudia": "Thank you very much for the professionalism and affection you had with me (Nurse Joana Ventura).",
            "home_testemonials_rita": "(...) I really want to thank all the impeccable people during our stay, Joana left a very positive mark on us for her patience, calmness, help (...)",
            "home_testemonials_joana_rita": "Nurse Joana Ventura of the cold hands, was without a doubt, an incredible professional, who fills me with pride in having such a beautiful profession (...). Many, many thanks.",
            "home_testemonials_anonimo1": "We love the pool class. Initially I was reticent about the experience, as I thought that the class would be a preparation for natural childbirth and I did not have that objective. The experience of the sounds that the baby hears in the belly, the exercises performed, which helped me in moments of pain in the movements of the baby in the belly, I learned exercises that helped me a lot and relaxation is fundamental. Thank you Enf. Andreia for giving us these good times (...).",
            "home_testemonials_anonimo2": "I had a prenatal aquarium session with nurse Andreia Durão and experienced a unique moment of relaxation, lightness and symbiosis with my baby. I was able to experience and understand various sensations on the baby's side, from how he listens when we talk to the moment he comes out into the world. It was beautiful and exciting, even. All with a perfect background playlist. In the end, you leave with a unique feeling of lightness! I sincerely thank you to nurse Andrea!",
            "home_testemonials_susana2": "We will recommend the service provided by our Enf. Andreia, to all our friends and family who will become future parents. Thank you for all the path we've traced together.",
            "home_testemonials_maria": "Excellent service.",
            "home_testemonials_raquel": "There are gestures worth a thousand words, and your presence during the cesarean section, your touch. It was so, so important! Like all your care during labor – Nurse Andreia.",
            "home_partners_flexus": "Flexus",
            "home_partners_acd": "Life Club Academy",
            //"home_partners_ma": "M.A. Osteopathy and Physiotherapy",
            "home_person_details_andreia_job": "Nurse Specialist in Maternal and Obstetric Health (SNMOH)",
            "home_person_details_andreia_description": 'Andreia, a nurse since 2011, was part of her first hospital team, in the adult/child post-surgical recovery area, but it was only in 2016 that she had contact with the maternity area. After 6 years, she felt the need to consolidate her basic training, finishing the Maternal Health and Obstetrics Specialty in 2022.',
            "home_person_details_andreia_description2": 'He currently works as SNMOH in a Delivery Block, in a Hospital in Greater Lisbon. With a main focus on accompanying and empowering women through their body awareness, breathing, relaxation and connection with the baby in the pre and postpartum period. Thus, its main areas of interest are Preparation for Childbirth in an Aquatic Environment, the support and promotion of Breastfeeding and Baby Sleep.',
            "home_person_details_andreia_experience_2011": "Nursing course conclusion",
            "home_person_details_andreia_experience_2016": "Started working in the field of Maternity and Obstetrics",
            "home_person_details_andreia_experience_2022": "Completed the specialty in Maternal and Obstetric Health",
            "home_person_details_joanaf_job": "Nurse Specialist in Maternal and Obstetric Health (SNMOH)",
            "home_person_details_joanaf_description": "Nurse since 2012 and working in the area of Maternal and Obstetric Health since 2013, she quickly felt the desire to know more about this fascinating area.",
            "home_person_details_joanaf_description2": "In 2014 she became a Counselor in Breastfeeding and in 2017 Nurse Specialist in Maternal and Obstetric Health. Since then, she has worked in the delivery room and emergency room at a maternity hospital in the greater Lisbon area, with a lot of taste, commitment and enthusiasm for what she does. Accompanying and overseeing the labor and birth process is a privilege and an area of interest, as is supporting the transition to parenthood.",
            "home_person_details_joanaf_experience_2012": "Nursing course conclusion",
            "home_person_details_joanaf_experience_2013": "Started working in the field of Maternity and Obstetrics",
            "home_person_details_joanaf_experience_2017": "Completed the specialty in Maternal and Obstetric Health",
            "home_person_details_joanav_job": "Nurse Specialist in Maternal and Obstetric Health (SNMOH)",
            "home_person_details_joanav_description": 'A nurse since 2013, Joana started her professional experience in the field of Obstetrics, having also worked in the medical-surgical area and worked in the Operating Room, completing a Post-Graduation in that area. Obstetrics quickly fascinated her and in 2017, she finished her specialty in Maternal and Obstetric Health.',
            "home_person_details_joanav_description2": "In the exercise of functions in a maternity unit in a hospital in greater Lisbon, some of his areas of interest are breastfeeding, pre and postpartum pain relief and childbirth on the move.",
            "home_person_details_joanav_experience_2013": "Nursing course conclusion",
            "home_person_details_joanav_experience_2014": "Started working in the field of Maternity and Obstetrics",
            "home_person_details_joanav_experience_2017": "Completed the specialty in Maternal and Obstetric Health",
            "home_person_details_paula_job": "Nurse Specialist in Maternal and Obstetric Health (SNMOH)",
            "home_person_details_paula_description": 'A nurse since 1994, Paula built her path between Pediatrics, Obstetrics and the Medical-Surgical area, namely in the Operating Room. However, Midwifery won her heart and in 2007 she completed her specialization in Maternal Health and Obstetric Nursing. She also has a postgraduate degree in Hypnosis and Bioethics.',
            "home_person_details_paula_description2": "Paula works at a maternity hospital in greater Lisbon and her areas of interest are 'Pediatrics', 'Breastfeeding' and 'Birth and Movement'.",
            "home_person_details_paula_experience_1994": "Nursing course conclusion",
            "home_person_details_paula_experience_2003": "Started working in the field of Maternity and Obstetrics",
            "home_person_details_paula_experience_2007": "Completed the specialty in Maternal and Obstetric Health",
            "person_detail_back_button": "Back to home page",
            "person_detail_experience_title": "Professional Experience",
            "courses_title": "Our courses",
            "courses_detail_back_button": "Back to courses list",
            "courses_detail_sessions_title": "Sessions details",
            "course_detail_prep_nascimento_name": "Birth tree - birth preparation and parenting course",
            "course_detail_prep_nascimento_description": "Train the woman/couple to:",
            "course_detail_prep_nascimento_goals_part1": "The experience of a positive parenting.",
            "course_detail_prep_nascimento_goals_part2": "Different stages of labor and delivery.",
            "course_detail_prep_nascimento_goals_part3": "Promoting autonomy and reducing anxiety.",
            "course_detail_prep_nascimento_goals_part4": "Breastfeeding process.",
            "course_detail_prep_nascimento_goals_part5": "Newborn care.",
            "course_detail_prep_nascimento_goals_title": "Goals",
            "course_detail_prep_nascimento_short_description": "Our Course aims to enable the woman/couple to experience positive parenting, from the prenatal period to the postpartum period.",
            "course_detail_prep_public_title": "Who is it for",
            "course_detail_prep_public": "It is intended for pregnant women/couples with a gestational age greater than 28 weeks, with the recommendation to start the course between 28 and 32 weeks.",
            "course_detail_prep_organization_title": "Organization",
            "course_detail_prep_organization_part1": "Expository, interactive and practical sessions.",
            "course_detail_prep_organization_part2": "Running time of approximately 90 minutes.",
            "course_detail_prep_organization_part3": "OFFER of 1 postpartum session.",
            "course_detail_prep_sessions_options_title": "Presential mode",
            "course_detail_prep_sessions_options_part1": "Home",
            //"course_detail_prep_sessions_options_part2": "Option 2: With our partner Flexus",
            "course_detail_prep_session1_name": "Session 1",
            "course_detail_prep_session2_name": "Session 2",
            "course_detail_prep_session3_name": "Session 3",
            "course_detail_prep_session4_name": "Session 4",
            "course_detail_prep_session5_name": "Session 5",
            "course_detail_prep_session1_description_part1": 'General aspects of pregnancy.',
            "course_detail_prep_session1_description_part2": "Warning signs.",
            "course_detail_prep_session1_description_part3": "Preparing for the going and discharge from the maternity hospital - transition to parenthood.",
            "course_detail_prep_session2_description_part1": "Anatomy and physiology of prepartum and childbirth.",
            "course_detail_prep_session2_description_part2": "Pelvic and uterine dynamics.",
            "course_detail_prep_session2_description_part3": "True and false signs of the onset of prepartum.",
            "course_detail_prep_session2_description_part4": "Addressing “pain” in prepartum.",
            "course_detail_prep_session2_description_part5": "Stage/stages of prepartum.",
            "course_detail_prep_session2_description_part6": "Parting types.",
            "course_detail_prep_session2_description_part7": "Ongoing support in prepartum: Role of the father/significant other.",
            "course_detail_prep_session3_description_part1": 'Exercises in pregnancy, prepartum and childbirth.',
            "course_detail_prep_session4_description_part1": "Puerperium (1. Physiological changes and discomfort in the postpartum period. 2. Adaptation of the woman/couple to the postpartum period).",
            "course_detail_prep_session4_description_part2": "Breastfeeding.",
            "course_detail_prep_session5_description_part1": 'Newborn care and childcare (1. Characteristics and immediate care for the newborn. 2. Hygiene and comfort care. 3. Accident prevention. 4. Transporting the baby safely. 5. Promotion and monitoring of child health.).',
            "course_detail_prep_session5_description_part2": "Birth plan",
            "services_title": "Our services",
            "services_detail_back_button": "Back to services list",
            "services_detail_sessions_title": "Service details",
            "services_detail_preparto_name": "Antepartum Nursing Consultation",
            "services_detail_preparto_description": "The service is available online or at home.",
            "services_detail_preparto_short_description": "Acquisition of a module or several modules of the Birth Preparation and Parenting course of your choice. The service also includes clarification of doubts and collaboration in the construction of the Birth Plan.",
            "services_detail_meio_aquatico_name": "Prepartum preparation in an aquatic environment",
            "services_detail_meio_aquatico_description": "According to scientific evidence, the benefits of water during pregnancy are proven, as it improves physical condition, reduces discomfort associated with body changes (edema, back pain), reduces anxiety and stress, improves body image, interpersonal relationships and promotion of mental and physical health. The course corresponds to a set of exercises performed in water for the woman/significant other, where it is possible to create a unique moment that encompasses body awareness and strengthening of the pelvic floor, mobility, breathing, relaxation and connection with the baby.",
            "services_detail_meio_aquatico_short_description": "When the benefits of water enhance your physical and mental well-being during pregnancy.",
            "services_detail_meio_aquatico_objectivo_title": "Goal",
            "services_detail_meio_aquatico_objectivo_description": "Promote the physical and mental health of pregnant women.",
            "services_detail_meio_aquatico_destino_title": "Who is it for",
            "services_detail_meio_aquatico_destino_description": "To all pregnant women after 24 weeks of pregnancy without associated risk.",
            "services_detail_meio_aquatico_estrutura_title": "How it is structured:",
            "services_detail_meio_aquatico_estrutura_description": "You can opt for a single session or a program of 4 monthly sessions (1x week). It can be performed exclusively by the pregnant woman or together with her significant other. Sessions are individual and has a duration of 45 minutes.",
            "services_detail_meio_aquatico_localizacao_title": "Location",
            "services_detail_meio_aquatico_localizacao_description": "It takes place at Academia Life Club at Estádio do Benfica, in Lisbon.",
            "services_detail_meio_aquatico_instructor_title": "Instructor",
            "services_detail_meio_aquatico_instructor_description": "Nurse Specialist in Maternal Health and Obstetrics Andreia Durão.",
            "services_detail_posparto_name": "Postpartum Nursing Consultation",
            "services_detail_posparto_description": "The service is available online or at home.",
            "services_detail_posparto_short_description": "Nursing consultation with a Specialist Nurse in Maternal and Obstetric Health, at home, according to the family's preference, by appointment, to clarify doubts and support in baby care and maternal surveillance.",
            "service_details_posparto_structure_description": "The Postpartum nursing consultation could be used for:",
            "service_details_posparto_structure1_name": "1. Support in the breastfeeding process:",
            "service_details_posparto_structure1_topic1": "The various positions of the baby in breastfeeding;",
            "service_details_posparto_structure1_topic2": "Care for the breasts/nipples, prevention of breast engorgement or support in its treatment;",
            "service_details_posparto_structure1_topic3": "Concerns about milk production, feeding during breastfeeding;",
            "service_details_posparto_structure1_topic4": "Fears and decision not to breastfeed.",
            "service_details_posparto_structure2_name": "2. Hygiene care, comfort and safety in the baby:",
            "service_details_posparto_structure2_topic1": "Bathing, dressing the baby, diaper change, caring for the umbilical stump;",
            "service_details_posparto_structure2_topic2": "Identify and promote colic relief strategies in the baby;",
            "service_details_posparto_structure2_topic3": "Crying, sleep and comfort and safe environment strategies.",
            "service_details_posparto_structure3_name": "3. Evaluate the baby's health signs:",
            "service_details_posparto_structure3_topic1": "Baby weight monitoring;",
            "service_details_posparto_structure3_topic2": "Performing the early diagnostic test (heel prick test).",
            "service_details_posparto_structure4_name": "4. Support in the postpartum recovery process:",
            "service_details_posparto_structure4_topic1": "Identification of signs of deviation from normality;",
            "service_details_posparto_structure4_topic2": "Wound care;",
            "service_details_posparto_structure4_topic3": "Teaching about massage to the abdominal or perineal scar;",
            "service_details_posparto_structure4_topic4": "Rehabilitation of the perineum – assessment of the need to strengthen the pelvic floor muscles;",
            "service_details_posparto_structure4_topic5": "Postpartum sexuality.",
            "service_details_posparto_structure5_name": "5. Your emotions: development of supportive parenting skills/positive reinforcement:",
            "service_details_posparto_structure5_topic1": "Psychological aspects of the puerperium and warning signs;",
            "service_details_posparto_structure5_topic2": "Postpartum emotional support/significant other.",
            "posts_title": "Some of our posts",
            "footer_text_part1": "Birth tree",
            "footer_text_part2": "© All rights reserved",
            "header_text": "Birth tree",
            "navbar_about": "About Us",
            "navbar_courses": "Courses",
            "navbar_services": "Services",
            "navbar_posts": "Posts",
            "list_more_button": "More info",
            "list_detail_price_title": "Price",
            "list_detail_language_title": "Language",
            "list_language_portuguese": "Portuguese",
            "list_language_english": "English",
            "list_item_price_label": "For more information about the price, contact us:",
            "home_slogan": "Here is born a life",
            "home_button": "Show more",
            "saude_in_city": "Saude in the city"
        }
    },
    pt: {
        translation: {
            "home_title": "Quem somos",
            "home_partners_section": "As nossas parcerias",
            "home_contacts_section": "Contacte-nos",
            "home_description_part1": "A Birth Tree é constituída por quatro Enfermeiras Especialistas em Saúde Materna e Obstétrica (EESMO). No decorrer do seu percurso profissional, quis o destino que estas enfermeiras se cruzassem na mesma instituição hospitalar. Foi nesse encontro que sentiram que a Obstetrícia poderia ir além do próprio parto e que o nascimento deveria ser um trabalho em equipa, entre a família e os profissionais de saúde, tendo início muito antes desse momento.",
            "home_description_part2": "Envolvidas ao longo desses anos na preparação, acompanhamento e empoderamento das famílias para a chegada de um novo membro, assumiram que reuniam as condições para iniciar um projeto só seu… ",
            "home_description_part3": "Assim nasceu a Birth Tree. Com o compromisso de partilhar, inovar e apoiar as mulheres, recém-nascido e família no seu ambiente, no seu tempo e de acordo com as suas necessidades e objetivos, na transição para uma Parentalidade positiva.",
            "home_testemonials_vanessa": "Profissionais de excelência! Adorei e recomendo a sessão de preparação em meio aquático e todo o acompanhamento pós-parto.",
            "home_testemonials_susana": "(...)Tive acompanhamento pós-parto e ajuda com a amamentação. Foi uma altura complicada, mas lá estava a enfermeira Joana Ventura a dar todo o seu apoio e sabedoria para que corresse tudo bem. (...) Recomendo, voltava a passar por tudo outra vez (...).",
            "home_testemonials_joana": "(...) Obrigado Enf. Joana Ventura, que me conduziu em todas as horas de indução, com toda a paciência, compreensão, conhecimento, respeito e carinho.(...)",
            "home_testemonials_andreia": "Joana Ventura é só uma das pessoas e profissionais mais maravilhosas que tive o prazer de conhecer!",
            "home_testemonials_patricia": "Quando iniciamos a expulsão, o trabalho da enfermeira que nos acompanha é fundamental e faz toda a diferença!! A Enf. Joana Ventura teve calma, pediu-me calma, para ir moderando a força, respirando (...) explicou tudo sempre, incentivou-me, elogiou-me: tudo isto fez diferença!",
            "home_testemonials_claudia": "Muito obrigado pelo profissionalismo e pelo carinho que teve comigo (Enf. Joana Ventura).",
            "home_testemonials_rita": "(...) quero agradecer mesmo mesmo muito a todas as pessoas impecáveis na nossa estadia a Joana marcou-nos de forma muito positiva pela paciência, calma, ajuda  (...)",
            "home_testemonials_joana_rita": "A Enf. Joana Ventura das mãos geladas, foi sem dúvida, uma profissional incrível, que me enche de orgulho em termos esta profissão tão bonita (...). Muito, muito obrigada.",
            "home_testemonials_anonimo1": "Adoramos a aula da Piscina. Inicialmente estava reticente à experiencia, pois achava que a aula, seria uma preparação para o parto natural e eu não tinha esse objectivo. A experiência dos sons que o bebé ouve na barriga, os exercícios realizados, que me ajudou em momentos de dor nos movimentos do bebé na barriga, aprendi exercicíos que me ajudaram bastante e o relaxamento é fundamental. Obrigado Enf. Andreia por nos ter proporcionado estes bons momentos (...).",
            "home_testemonials_anonimo2": "Fiz uma sessão aquária pré-natal com a enf Andreia Durão e vivi um momento único de relaxamento, leveza e simbiose com a minha bebé. Pude experienciar e entender várias sensações do lado do bebé, desde como ouve quando falamos ao momento de saída para o mundo. Foi lindo e emocionante, até. Tudo com uma perfeita playlist de fundo. No fim sai com uma sensação de leveza única! Agradeço de coração à Enf. Andreia!",
            "home_testemonials_susana2": "Iremos recomendar o serviço prestado pela nossa Enf. Andreia, a todos os nossos amigos e familiares que venham a ser futuros papás. Obrigada por todo o caminho que traçamos juntos.",
            "home_testemonials_maria": "Excelente serviço.",
            "home_testemonials_raquel": "Há gestões que valem por mil palavras, e a tua presença durante a cesariana, o teu toque. Foi tão, mas tão importante! Tal como todo o teu cuidado durante o trabalho de parto – Enfª Andreia.",
            "home_partners_flexus": "Flexus",
            "home_partners_acd": "Academia Life Club",
            //"home_partners_ma": "M.A. Osteopatia e Fisioterapia",
            "home_person_details_andreia_job": "Enfermeira Especialista em Saúde Materna e Obstétrica (EESMO)",
            "home_person_details_andreia_description": 'A Andreia, enfermeira desde 2011, integrou a sua primeira equipa a nível hospitalar, na área do recobro pós-cirúrgico do adulto/criança, mas só em 2016, teve contacto com a área da maternidade. Após 6 anos, sentiu necessidade de consolidar a sua formação base, terminando a Especialidade de Saúde Materna e Obstetrícia em 2022.',
            "home_person_details_andreia_description2": 'Atualmente exerce funções como EESMO num Bloco de Partos, num Hospital da grande Lisboa. Com enfoque principal no acompanhamento e empoderamento das mulheres pela sua consciência corporal, respiração, relaxamento e conexão com o bebé no pré e pós-parto. Assim, tem como principais áreas de interesse a Preparação para o Parto em Meio Aquático, o apoio e promoção do Aleitamento Materno e Sono do bebé.',
            "home_person_details_andreia_experience_2011": "Conclusão do curso de Enfermagem",
            "home_person_details_andreia_experience_2016": "Começou a trabalhar na área da Maternidade e Obstétricia",
            "home_person_details_andreia_experience_2022": "Concluiu a especialidade em Saúde Materna e Obstétrica",
            "home_person_details_joanaf_job": "Enfermeira Especialista em Saúde Materna e Obstétrica (EESMO)",
            "home_person_details_joanaf_description": "Enfermeira desde 2012 e a exercer funções na área da Saúde Materna e Obstétrica desde 2013, rapidamente sentiu vontade de saber mais sobre esta área fascinante.",
            "home_person_details_joanaf_description2": "Em 2014 tornou-se Conselheira em Aleitamento Materno e em 2017 Enfermeira Especialista em Saúde Materna e Obstétrica. Desde então exerce funções na sala de partos e urgência de uma maternidade na zona da grande Lisboa, com muito gosto, empenho e entusiasmo pelo que faz. Acompanhar e vigiar o trabalho de parto e processo de nascimento é um privilégio e uma área de interesse, assim como apoiar a transição para a parentalidade.",
            "home_person_details_joanaf_experience_2012": "Conclusão do curso de Enfermagem",
            "home_person_details_joanaf_experience_2013": "Começou a trabalhar na área da Maternidade e Obstétricia",
            "home_person_details_joanaf_experience_2017": "Concluiu a especialidade em Saúde Materna e Obstétrica",
            "home_person_details_joanav_job": "Enfermeira Especialista em Saúde Materna e Obstétrica (EESMO)",
            "home_person_details_joanav_description": 'Enfermeira desde 2013, a Joana iniciou a sua experiência profissional na área da Obstetrícia, tendo passado também pelo área médico-cirúrgica e exercido funções em Bloco Operatório, concluindo uma Pós-Graduação nessa área. Rapidamente a Obstetrícia a fascinou e em 2017, terminou a sua especialidade em Saúde Materna e Obstétrica.',
            "home_person_details_joanav_description2": "Em exercício de funções numa Maternidade de um hospital da grande Lisboa, algumas das suas áreas de interesse são o aleitamento Materno, alívio da dor no pré e pós-parto e Parto em movimento.",
            "home_person_details_joanav_experience_2013": "Conclusão do curso de Enfermagem",
            "home_person_details_joanav_experience_2014": "Começou a trabalhar na área da Maternidade e Obstétricia",
            "home_person_details_joanav_experience_2017": "Concluiu a especialidade em Saúde Materna e Obstétrica",
            "home_person_details_paula_job": "Enfermeira Especialista em Saúde Materna e Obstétrica (EESMO)",
            "home_person_details_paula_description": 'Enfermeira desde 1994, a Paula construiu o seu percurso entre a Pediatria, a Obstetrícia e a área Médico-cirúrgica, nomeadamente em Bloco Operatório. No entanto, a Obstetrícia conquistou o seu coração e em 2007 concluiu a sua especialização em Enfermagem de Saúde Materna e Obstétrica. Conta também com uma Pós-graduação em Hipnose e Bioética.',
            "home_person_details_paula_description2": "A Paula exerce funções numa Maternidade da grande Lisboa e as suas áreas de interesse são 'Pediatria','Aleitamento Materno' e 'Parto e Movimento'.",
            "home_person_details_paula_experience_1994": "Conclusão do curso de Enfermagem",
            "home_person_details_paula_experience_2003": "Começou a trabalhar na área da Maternidade e Obstétricia",
            "home_person_details_paula_experience_2007": "Concluiu a especialidade em Saúde Materna e Obstétrica",
            "person_detail_back_button": "Voltar a página principal",
            "person_detail_experience_title": "Experiência Profissional",
            "courses_title": "Os nossos cursos",
            "courses_detail_back_button": "Voltar a lista de cursos",
            "courses_detail_sessions_title": "Detalhe das sessões",
            "course_detail_prep_nascimento_name": "Curso de preparação para o nascimento e parentalidade Birth tree",
            "course_detail_prep_nascimento_description": "Capacitar a mulher/casal para:",
            "course_detail_prep_nascimento_goals_part1": "A vivência de uma parentalidade positiva.",
            "course_detail_prep_nascimento_goals_part2": "Diferentes estadios de trabalho de parto e parto.",
            "course_detail_prep_nascimento_goals_part3": "Promoção da autonomia e diminuição da ansiedade.",
            "course_detail_prep_nascimento_goals_part4": "Processo de aleitamento materno.",
            "course_detail_prep_nascimento_goals_part5": "Cuidados ao recém-nascido.",
            "course_detail_prep_nascimento_goals_title": "Objetivos",
            "course_detail_prep_nascimento_short_description": "O nosso Curso tem como objetivo capacitar a mulher/casal para a vivência de uma parentalidade positiva, desde o período pré-natal até ao pós-parto.",
            "course_detail_prep_public_title": "A quem se destina",
            "course_detail_prep_public": "Destina-se a grávidas/casais com idade gestacional superior às 28 semanas, estando recomendado o início do curso entre as 28 e as 32 semanas. ",
            "course_detail_prep_organization_title": "Organização",
            "course_detail_prep_organization_part1": "Sessões expositivas, interativas e práticas.",
            "course_detail_prep_organization_part2": "Tempo de duração de aproximadamente 90 minutos.",
            "course_detail_prep_organization_part3": "OFERTA de 1 sessão Pós-parto.",
            "course_detail_prep_sessions_options_title": "Modo presencial",
            "course_detail_prep_sessions_options_part1": "Domicílio",
            //"course_detail_prep_sessions_options_part2": "Opção 2: Com o nosso parceiroFlexus",
            "course_detail_prep_session1_name": "Sessão 1",
            "course_detail_prep_session2_name": "Sessão 2",
            "course_detail_prep_session3_name": "Sessão 3",
            "course_detail_prep_session4_name": "Sessão 4",
            "course_detail_prep_session5_name": "Sessão 5",
            "course_detail_prep_session1_description_part1": 'Aspetos gerais da gravidez.',
            "course_detail_prep_session1_description_part2": "Sinais de alerta.",
            "course_detail_prep_session1_description_part3": "Preparação da ida e da alta da maternidade – transição para a parentalidade.",
            "course_detail_prep_session2_description_part1": "Anatomia e fisiologia do trabalho de parto e parto.",
            "course_detail_prep_session2_description_part2": "Dinâmica pélvica e uterina.",
            "course_detail_prep_session2_description_part3": "Sinais verdadeiros versus falsos de início de trabalho de parto.",
            "course_detail_prep_session2_description_part4": "Abordagem da “dor” no trabalho de parto.",
            "course_detail_prep_session2_description_part5": "Etapa/estadios de trabalho de parto.",
            "course_detail_prep_session2_description_part6": "Tipos de Parto.",
            "course_detail_prep_session2_description_part7": "Apoio contínuo no trabalho de parto: Papel do Pai/pessoa significativa.",
            "course_detail_prep_session3_description_part1": 'Exercícios na gravidez, trabalho de parto e parto.',
            "course_detail_prep_session4_description_part1": "Puerpério (1. Alterações fisiológicas e desconfortos no pós-parto. 2. Adaptação da mulher/casal ao pós-parto).",
            "course_detail_prep_session4_description_part2": "Aleitamento materno.",
            "course_detail_prep_session5_description_part1": 'Cuidados ao recém-nascido e puericultura (1. Caraterísticas e cuidados imediatos ao recém-nascido. 2. Cuidados de higiene e conforto. 3. Prevenção de acidentes. 4. Transporte do bebé em segurança. 5. Promoção e vigilância de saúde infantil.).',
            "course_detail_prep_session5_description_part2": "Plano de nascimento.",
            "services_title": "Os nossos serviços",
            "services_detail_back_button": "Voltar a lista de serviços",
            "services_detail_sessions_title": "Detalhes do serviço",
            "services_detail_preparto_name": "Consulta de Enfermagem pré-parto",
            "services_detail_preparto_description": "O serviço está disponível em modo on-line ou no domicílio.",
            "services_detail_preparto_short_description": "Aquisição de um módulo ou vários módulos do curso de Preparação para o Nascimento e Parentalidade à escolha. O serviço inclui ainda esclarecimento de dúvidas e colaboração na construção do Plano de Nascimento.",
            "services_detail_meio_aquatico_name": "Preparação Pré-Parto em meio aquático",
            "services_detail_meio_aquatico_description": "Segundo a evidência científica, está comprovado os benefícios da água durante a gravidez, pois permite melhorar a condição física, diminuir os desconfortos associados às alterações corporais (edemas, lombalgias), reduzir a ansiedade e stresse, melhorar a imagem corporal, as relações interpessoais e promoção da saúde mental e física. O curso corresponde a um conjunto de exercícios realizados na água para a mulher/pessoa significativa, onde é possível criar um momento único que engloba consciência corporal e fortalecimento do pavimento pélvico, mobilidade, respiração, relaxamento e conexão com o bebé. ",
            "services_detail_meio_aquatico_short_description": "Quando os benefícios da água potenciam com o seu bem-estar físico e mental, durante a gravidez.",
            "services_detail_meio_aquatico_objectivo_title": "Objetivo",
            "services_detail_meio_aquatico_objectivo_description": "Promover a saúde física e mental da grávida.",
            "services_detail_meio_aquatico_destino_title": "A quem se destina",
            "services_detail_meio_aquatico_destino_description": "A todas as mulheres grávidas após as 24 semanas de gravidez sem risco associado.",
            "services_detail_meio_aquatico_estrutura_title": "Como está estruturado:",
            "services_detail_meio_aquatico_estrutura_description": "Pode optar por uma sessão única ou um programa de 4 sessões mensais (1x semana). Pode ser realizado exclusivamente pela grávida ou em conjunto com a pessoa significativa. As sessões são individuais e tem uma duração de 45 minutos.",
            "services_detail_meio_aquatico_localizacao_title": "Localização",
            "services_detail_meio_aquatico_localizacao_description": "É realizado na Academia Life Club no Estádio do Benfica, em Lisboa.",
            "services_detail_meio_aquatico_instructor_title": "Instrutor",
            "services_detail_meio_aquatico_instructor_description": "Enf.ª Especialista de Saúde Materna e Obstetrícia Andreia Durão",
            "services_detail_posparto_name": "Consulta de Enfermagem pós-parto",
            "services_detail_posparto_description": "O serviço está disponível em modo on-line ou no domicílio",
            "services_detail_posparto_short_description": "Consulta de enfermagem com Enfermeira Especialista em Saúde Materna e Obstétrica, no domicílio, de acordo com a preferência da família, mediante agendamento, para esclarecimento de dúvidas e apoio nos cuidados ao bebé e vigilância materna.",
            "service_details_posparto_structure_description": "As consultas de acompanhamento no pós-parto, podem ser utilizadas para:",
            "service_details_posparto_structure1_name": "1. Apoio no processo de aleitamento materno:",
            "service_details_posparto_structure1_topic1": "As várias posições do bebé na amamentação;",
            "service_details_posparto_structure1_topic2": "Cuidados com as mamas/mamilos, prevenção do ingurgitamento mamário ou apoio no seu tratamento (Subida de Leite);",
            "service_details_posparto_structure1_topic3": "Preocupações com a produção de leite, alimentação durante a amamentação;",
            "service_details_posparto_structure1_topic4": "Medos e decisão de não amamentar.",
            "service_details_posparto_structure2_name": "2. Cuidados de higiene, conforto e segurança no bebé:",
            "service_details_posparto_structure2_topic1": "Banho, vestir o bebé, muda da fralda, cuidados ao coto umbilical;",
            "service_details_posparto_structure2_topic2": "Identificar e promover estratégias de alívio de cólicas no bebé;",
            "service_details_posparto_structure2_topic3": "Choro, sono e estratégias de conforto e de ambiente seguro.",
            "service_details_posparto_structure3_name": "3. Avaliar os sinais de saúde do bebé:",
            "service_details_posparto_structure3_topic1": "Monitorização do peso do bebé;",
            "service_details_posparto_structure3_topic2": "Realização do teste de diagnóstico precoce (teste do pezinho).",
            "service_details_posparto_structure4_name": "4. Apoio no processo de recuperação pós-parto:",
            "service_details_posparto_structure4_topic1": "Identificação de sinais de desvio à normalidade;",
            "service_details_posparto_structure4_topic2": "Tratamento de feridas;",
            "service_details_posparto_structure4_topic3": "Ensino sobre a massagem à cicatriz abdominal ou perineal;",
            "service_details_posparto_structure4_topic4": "Reabilitação do períneo – avaliação da necessidade de fortalecimento dos músculos do pavimento pélvico;",
            "service_details_posparto_structure4_topic5": "Sexualidade no pós-parto.",
            "service_details_posparto_structure5_name": "5. As vossas emoções: desenvolvimento de competências parentais apoiadas/reforço positivo:",
            "service_details_posparto_structure5_topic1": "Aspetos psicológicos do puerpério e sinais de alerta;",
            "service_details_posparto_structure5_topic2": "Apoio emocional puérpera/pessoa significativa.",
            "posts_title": "Algumas das nossas publicações",
            "footer_text_part1": "Birth tree",
            "footer_text_part2": "© Todos os direitos reservados",
            "header_text": "Birth tree",
            "navbar_about": "Sobre nós",
            "navbar_courses": "Cursos",
            "navbar_services": "Serviços",
            "navbar_posts": "Publicações",
            "list_more_button": "Mais informação",
            "list_detail_price_title": "Preço",
            "list_detail_language_title": "Idiomas",
            "list_language_portuguese": "Português",
            "list_language_english": "Inglês",
            "list_item_price_label": "Para mais informação sobre o preço, contacte-nos: ",
            "home_slogan": "Aqui nasce uma vida",
            "home_button": "Ver mais",
            "saude_in_city": "Saude in the city"
        }
    }
};

i18n
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources,
        //lng: "en",
    });
export default i18n;