
import { InstagramEmbed } from 'react-social-media-embed';
import { posts_data } from '../../mock-data';
import "./posts.css";
import { useTranslation } from 'react-i18next';

/**
 * @page Posts
 * Render birth three posts page
 */
export default function Posts() {

    const { t } = useTranslation();

    return (
        <div className='posts_root'>
            <h2>{t("posts_title")}</h2>
            <div className='posts_content'>
                {
                    posts_data.map((post, index) => {
                        return (
                            <div key={"post_"+index} className='post_item'>
                                <InstagramEmbed url={post.url} width={"100%"} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}
