import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { logo, logo_dark } from '../../mock-data/index';
import "./home.css";

/**
 * @page Home
 * Render Home page
 */
export default function Home({ handleHideHome }: HomeProps) {

    const { t } = useTranslation();
    const [theme, setTheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light");

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', event => {
                setTheme(event.matches ? "dark" : "light");
            });
    }, []);
    
    return (
        <div className='app_home_root'>
            <div className='app_home_section_one' />
            <div className='app_home_section_two'>
                <>
                    <div className='app_home_section_two_logo'>
                        <img src={theme === "light" ? logo : logo_dark} />
                        <span>Birth tree</span>
                    </div>
                    <div className='app_home_section_two_slogan'>
                        <span>{t("home_slogan")}</span>
                    </div>
                </>
                <div className='app_home_section_two_button'>
                    <a
                        className='app_home_button'
                        href="#us"
                        onClick={handleHideHome}>{t("home_button")}</a>
                </div>
            </div>
        </div>
    );
}
