import './internal-footer.css';
import { partners, contactus } from '../../../mock-data';
import { useTranslation } from 'react-i18next';

/**
 * @component Internalfooter
 * home page partners/contacts section
 */
export default function Internalfooter() {

    const { t } = useTranslation();

    /**
     * @function changePartnerBackground
     * Change partner background image on hover
     */
    const changePartnerBackground = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, partner: PartnerDetails) => {
        evt.currentTarget.style.backgroundImage = "url(" + partner.image + ")";
    }

    /**
     * @function onMouseLeave
     * Change partner background image on mouse leave
     */
    const onMouseLeave = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.currentTarget.style.backgroundImage = "none";
    }

    return (
        <div className="app_about_footer">
            <div>
                <h2> {t("home_partners_section")} </h2>
                <div className="app_about_partners">
                    {
                        partners.map((partner) => {
                            return (
                                <div
                                    key={"partner_" + partner.name}
                                    onMouseLeave={onMouseLeave}
                                    onMouseOver={(evt) => { changePartnerBackground(evt, partner) }}
                                    onClick={() => window.open(partner.link)}
                                    className='app_about_partners_item'>
                                    <span> {t(partner.name)} </span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div>
                <h2> {t("home_contacts_section")} </h2>
                <div className="app_about_contactus">
                    {contactus.map((value, index) => {
                        return (
                            <div
                                key={"value_" + index}
                                className="app_about_contactus_item">
                                <span> {value.icon} </span>
                                <span> {value.value} </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
