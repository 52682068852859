import './footer.css';
import { useTranslation } from 'react-i18next';

/**
 * @component Footer
 * Birth tree footer
 */
export default function Footer() {

    const { t } = useTranslation();

    return (
        <div className="app_footer">
            <span className='body2'>{t("footer_text_part1")} {new Date()?.getFullYear()} {t("footer_text_part2")} </span>
        </div>
    );
}