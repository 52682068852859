import './list.css';
import { useState } from "react";
import ListItem from "./listitem";
import { useTranslation } from 'react-i18next';

/**
 * @component List
 * Used to list services and courses in pages
 */
export default function List({
    data,
    title,
    labels,
}: ListProps) {

    const [itemSelected, setItemSelected] = useState<any>(null);
    const { t } = useTranslation();

    /**
     * @function onItemClicked
     * Open item details
     */
    const onItemClicked = (item: ListItemProps) => {
        setItemSelected(item);
    }

    return (
        <div className='list_root'>
            {
                itemSelected === null ?
                    <>
                        <h2>{title}</h2>
                        <div>
                            <div className='list_container'>
                                {
                                    data.map((elem, index) => {
                                        return (
                                            <div
                                                key={"list_item_" + index}
                                                className='list_item'>
                                                <img src={elem.image} alt="list item" />
                                                <div>
                                                    <h5>{t(elem.name)}</h5>
                                                    <span className='divider_horizontal' />
                                                    <span className='body2'>
                                                        {t(elem.short_description)}
                                                    </span>
                                                    <div>
                                                        <button
                                                            className='default_button'
                                                            onClick={() => { onItemClicked(elem) }}>
                                                            {t("list_more_button")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                    :
                    <ListItem
                        item={itemSelected}
                        labels={labels}
                        handleBack={() => { setItemSelected(null) }} />
            }
        </div>
    );
}
