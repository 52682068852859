import "./listitem.css";
import { useTranslation } from 'react-i18next';
import { contactus } from '../../../mock-data/index';

/**
 * @component ListItem
 * Item details of List component
 */
export default function ListItem({ item, handleBack, labels }: ListItem) {

    const { t } = useTranslation();

    return (
        <div className="list_item_root">
            <button
                className="button_text"
                onClick={handleBack}>
                <span className="material-symbols-outlined">arrow_back_ios</span>
                {labels.backbutton}
            </button>
            <div>
                <div className="list_item_overview">
                    <h1>{t(item.name)}</h1>
                    <span className="body1">
                        {t(item.short_description)}
                    </span>
                    {
                        item.structure_goals ?
                            <div className="list_item_overview_goals">
                                <h5>
                                    {t("course_detail_prep_nascimento_goals_title")}
                                </h5>
                                <span className="body1">
                                    {t(item.description)}
                                </span>
                                {
                                    item.structure_goals.map((goal: string) => {
                                        return (
                                            <span className="body1">
                                                • {t(goal)}
                                            </span>
                                        )
                                    })
                                }
                            </div> :
                            <span className="body1">
                                {t(item.description)}
                            </span>
                    }
                    {
                        item.public &&
                        <div className="list_item_overview_public">
                            <h5>
                                {t("course_detail_prep_public_title")}
                            </h5>
                            <span className="body1">
                                {t(item.public)}
                            </span>
                        </div>
                    }
                    {
                        item.organization &&
                        <div className="list_item_overview_organization">
                            <h5>
                                {t("course_detail_prep_organization_title")}
                            </h5>
                            {
                                item.organization.map((organizationitem: string) => {
                                    return (
                                        <span className="body1">
                                            • {t(organizationitem)}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        item.sessions_options &&
                        <div className="list_item_overview_organization">
                            <h5>
                                {t("course_detail_prep_sessions_options_title")}
                            </h5>
                            {
                                item.sessions_options.map((option: string) => {
                                    return (
                                        <span className="body1">
                                            • {t(option)}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
                <span className="divider_vertical" />
                <div className="list_item_information">
                    <div>
                        <div>
                            <h2>{t("list_detail_price_title")}</h2>
                            <span className="list_detail_price_value">
                                {t(item.price)}
                            </span>
                            {
                                item.price === "list_item_price_label" &&
                                <div className="list_item_price_contactus">
                                    {contactus.map((value, index) => {
                                        return (
                                            <div
                                                key={"value_" + index}
                                                className="list_item_price_contactus_item">
                                                <span> {value.icon} </span>
                                                <span> {value.value} </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                        <div>
                            <h2>{t("list_detail_language_title")}</h2>
                            <div className="list_item_languages">
                                {
                                    item.languages.map((language: Language) => {
                                        return (
                                            <div className="list_item_languages_item">
                                                <span>{t(language.name)}</span>
                                                <img src={language.flag} className="list_item_languages_item_image" alt="flag" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {
                        item.structure.length > 0 &&
                        <div className="list_item_structure">
                            <h2>{labels.structuretitle}</h2>
                            {
                                item?.structure_description &&
                                <span className="body1">{t(item.structure_description)}</span>
                            }
                            <br />
                            <div>
                                {
                                    item.structure.map((session: Structure) => {
                                        return (
                                            <div className="list_item_structure_item">
                                                <h5>
                                                    {t(session.name)}
                                                </h5>

                                                {
                                                    session.description.map(
                                                        (
                                                            descriptionItem: string) => {
                                                            return (
                                                                <span className="body1">
                                                                    • {t(descriptionItem)}
                                                                </span>
                                                            )
                                                        })
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}