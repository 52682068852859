import { aboutus } from '../../../mock-data';
import { useNavigate } from 'react-router-dom';
import './photo.css';

/**
 * @component PhotoGallery
 * Renders photo galary in home page
 */
export default function PhotoGallery() {

    const navigate = useNavigate();

    return (
        <div className="about_section_gallery">
            {
                aboutus.map((user, index) => {
                    return (
                        <img
                            key={"team_" + index}
                            className="about_section_gallery_images"
                            src={user.photo} alt=""
                            onClick={() => navigate('/about/' + user.name)} />
                    );
                })
            }
        </div>
    );
}
