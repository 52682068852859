import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaQuoteLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PersonalDescription from './personal-description';
import Internalfooter from './internal-footer';
import PhotoGallery from './photos';
import { aboutus, description, testemonials } from '../../mock-data';
import { Swiper, SwiperSlide } from 'swiper/react';
import './about.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper';

/**
 * @page About - home page of birth tree.
 * Contains personal information, birth tree information, testemonials and contacts
 */
export default function About() {

    const { t } = useTranslation();
    const { contact } = useParams();
    const navigate = useNavigate();
    const [person, setPerson] = useState<Person | undefined>();

    useEffect(() => {
        let person = aboutus.find((value) => value.name === contact);

        if (!person) {
            navigate('/about', { replace: true });
        } else {
            setPerson(person);
        }
    }, [contact]);

    return (
        <>
            {
                !!contact && !!person ? (
                    <PersonalDescription person={person} />
                ) : (
                    <div className="app_about_root">
                        <h2 className="h2">{t("home_title")}</h2>
                        <div className="app_about_main">
                            <div>
                                <p className="body1">{t(description.description_1)}</p>
                                <p className="body1">{t(description.description_2)}</p>
                                <p className="body1">{t(description.description_3)}</p>
                            </div>
                            <PhotoGallery />
                        </div>
                        <div className='app_testemonials_root'>
                            <div style={{ width: '100%' }}>
                                <Swiper
                                    autoplay={{
                                        delay: 9000
                                    }}
                                    pagination={true}
                                    navigation={true}
                                    modules={[Pagination, Navigation, Autoplay]}>
                                    {
                                        testemonials.map((value) => {
                                            return (
                                                <SwiperSlide>
                                                    <div className="app_testemonials_item">
                                                        <FaQuoteLeft />
                                                        <span className="body1">
                                                            {t(value.description)}
                                                        </span>
                                                        <span className="body2">
                                                            {value.author}
                                                        </span>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                        <Internalfooter />
                    </div>
                )
            }
        </>
    );
}
