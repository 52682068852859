import './navbar.css';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

/**
 * @component Navbar
 * Birth tree navbar
 */
export default function Navbar() {

    const [isMobile, setIsMobile] = useState(false);
    const [isHumburgerOpen, setHumburguerState] = useState(false);
    const { t } = useTranslation();

    /**
     * @function handleResize
     */
    const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
    }

    /**
     * Execute when component is mounted
     */
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        setIsMobile(window.innerWidth < 900);
    }, []);

    const handleCloseHumburguer = () => {

    }

    return (
        <>
            {
                !isMobile ?
                    <ul className="app_navbar-links">
                        <li>
                            <NavLink to="about">{t("navbar_about")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="courses">{t("navbar_courses")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="services">{t("navbar_services")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="posts">{t("navbar_posts")}</NavLink>
                        </li>
                    </ul>
                    : <div className="nav-container">
                        <input
                            checked={isHumburgerOpen}
                            onClick={() => { setHumburguerState(!isHumburgerOpen) }}
                            className="checkbox"
                            type="checkbox"
                            name=""
                            id="" />
                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                        <div className="menu-items">
                            <li onClick={() => setHumburguerState(false)}>
                                <NavLink to="about">{t("navbar_about")}</NavLink>
                            </li>
                            <li onClick={() => setHumburguerState(false)}>
                                <NavLink to="courses">{t("navbar_courses")}</NavLink>
                            </li>
                            <li onClick={() => setHumburguerState(false)}>
                                <NavLink to="services">{t("navbar_services")}</NavLink>
                            </li>
                            <li onClick={() => setHumburguerState(false)}>
                                <NavLink to="posts">{t("navbar_posts")}</NavLink>
                            </li>
                        </div>
                    </div >
            }
        </>
    );
}
