import { courses_data } from "../../mock-data";
import List from "../../components/list";
import { useTranslation } from 'react-i18next';

/**
 * @page Courses
 * Render courses page
 */
export default function Courses() {

    const { t } = useTranslation();

    return (
        <List
            data={courses_data}
            title={t("courses_title")}
            labels={{
                backbutton: t("courses_detail_back_button"),
                structuretitle: t("courses_detail_sessions_title")
            }}
        />
    );
}
