import { useEffect, useState } from "react";
import { logo, logo_dark } from '../../mock-data/index';
import './header.css';
import { useTranslation } from 'react-i18next';

/**
 * @component Header
 * Birth tree header 
 */
export default function Header() {

    const { t } = useTranslation();
    const [theme, setTheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light");

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', event => {
                setTheme(event.matches ? "dark" : "light");
            });
    }, []);

    return (
        <div className="app_header">
            <img src={theme === "light" ? logo : logo_dark} alt="Birth Tree" />
            <div>
                <span>{t("header_text")}</span>
            </div>
        </div>
    );
}
