import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/about';
import Courses from './pages/courses';
import Posts from './pages/posts';
import Services from './pages/services';
import Home from './pages/home';
import Header from './components/header';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { I18nextProvider } from 'react-i18next';
import i18n from './providers/i18n';
import { useEffect, useState } from 'react';

/**
 * Birth tree root component
 */
function App() {

    const [showHome, setShowHome] = useState(true);

    useEffect(() => {
        if (!showHome) {
            const timer = setTimeout(() => {
                let elem = document.getElementById("home");
                if (elem)
                    elem.style.display = "none"
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [showHome]);

    return (
        <I18nextProvider i18n={i18n} defaultNS={'translation'}>
            <div className="app">
                <div id="home">
                    <Home
                        handleHideHome={() => { setShowHome(false) }} />
                </div>
                <div id="us" style={{ display: showHome ? "none" : "inherit" }}>
                    <Router>
                        <Header />
                        <Navbar />
                        <Routes>
                            <Route path="/about" element={<About />} />
                            <Route path="/about/:contact" element={<About />} />
                            <Route path="/courses" element={<Courses />} />
                            <Route path="/posts" element={<Posts />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="*" element={<Navigate to="/about" replace={true} />} />
                        </Routes>
                    </Router>
                </div>
                <Footer />
            </div>
        </I18nextProvider>
    );
}
export default App;
